import {Box, IconButton, Typography, useTheme} from '@mui/material';
import * as RDS from '@verily-src/react-design-system';
import {useEffect, useRef, useState} from 'react';
import {HeaderSlotDropdownConfigs, HeaderSlotItem} from '../../api/Nav';
import {getRDSLogo} from '../../root.component';

type HeaderSlotProps = {
  headerSlot: HeaderSlotItem;
  navOpen: boolean;
};

/**
 * Returns a styled slot for the nav header if the given HeaderSlot is
 * non-null, otherwise it returns an empty fragment and no header slot is
 * rendered.
 */
export function HeaderSlot(props: HeaderSlotProps) {
  const {headerSlot, navOpen} = props;
  const theme = useTheme();

  if (!headerSlot) {
    return <></>;
  }
  const borderStyles = {
    borderStyle: 'solid',
    borderColor: theme.palette.background.separator,
    borderWidth: '1px',
    borderRadius: theme.spacing(1),
  };
  if (navOpen) {
    const dropdownConfigs = headerSlot.dropdownConfigs;
    if (dropdownConfigs && dropdownConfigs.options.length > 1) {
      return <HeaderSlotDropdown dropdownConfigs={dropdownConfigs} />;
    }
    return (
      <HeaderSlotTextBox text={headerSlot.name} borderStyles={borderStyles} />
    );
  }

  return (
    <HeaderSlotIcon
      tooltipTitle={headerSlot.tooltip}
      iconName={headerSlot.icon}
      borderStyles={borderStyles}
    />
  );
}

type HeaderSlotDropdownProps = {
  dropdownConfigs: HeaderSlotDropdownConfigs;
};

function HeaderSlotDropdown(props: HeaderSlotDropdownProps) {
  const {
    dropdownConfigs: {
      options,
      ariaLabel,
      onSelectOptionCallback,
      defaultValue,
      currentSelectionAnnouncement,
    },
  } = props;
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const theme = useTheme();
  const isMounted = useRef(false);

  useEffect(() => {
    // aria-live regions need to be updated after mounting to be picked up by screen reader
    // hide content of header-slot-announcement until component is mounted
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <div
        id="header-slot-announcement"
        role="alert"
        style={{
          position: 'absolute',
          left: -10000,
          height: 1,
          width: 1,
          overflow: 'hidden',
        }}
      >
        {isMounted.current && currentSelectionAnnouncement}
      </div>
      <RDS.SingleSelectDropdown
        ariaLabel={ariaLabel}
        value={selectedValue}
        onChange={(e) => {
          const value = e.target.value as string;
          setSelectedValue(value);
          onSelectOptionCallback(value);
        }}
        options={options}
        formControlSx={{width: '100%', marginBottom: theme.spacing(1.5)}}
        sx={{width: '12rem'}}
        inputProps={{
          sx: {
            minHeight: theme.spacing(3),
            '& p': {fontSize: theme.typography.body2.fontSize},
          },
        }}
      />
    </>
  );
}

type HeaderSlotTextBoxProps = {
  text: string;
  borderStyles: Record<string, string>;
};

function HeaderSlotTextBox(props: HeaderSlotTextBoxProps) {
  const {text, borderStyles} = props;
  const theme = useTheme();
  return (
    <Box
      data-cy="header-slot"
      sx={{
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
        ...borderStyles,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.text.muted,
          // Limit text to 2 lines
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

type HeaderSlotIconProps = {
  tooltipTitle: string;
  iconName: string;
  borderStyles: Record<string, string>;
};

function HeaderSlotIcon(props: HeaderSlotIconProps) {
  const {tooltipTitle, iconName, borderStyles} = props;
  const theme = useTheme();
  const icon = getRDSLogo(iconName);
  return (
    <RDS.Tooltip title={tooltipTitle} placement="right">
      <IconButton
        data-cy="header-slot-icon"
        style={{
          color: theme.palette.icon.muted,
          backgroundColor: 'transparent',
          cursor: 'default',
          ...borderStyles,
        }}
      >
        {icon}
      </IconButton>
    </RDS.Tooltip>
  );
}
